import { useEffect, useState } from 'react';
import { useApolloClient } from '@apollo/client';
import { Button, Form, Input } from 'reactstrap';
import moment from 'moment';
import { errorMsg, successMsg } from '../../cache/vars';
import GooglePlaceAutocompleteInput from '../GooglePlaceAutocomplete/GooglePlaceAutocomplete';
import CardDeclineBtn from '../StagedCard/CardDeclineBtn/CardDeclineBtn';
import { DECLINE_SERVICE, GET_AVAILABLE_FILTERS_FOR_PACKAGE_INFO, SAVE_STAGE_ONE_CARD } from '../../queries/vip-workflow';
import { SERVICE_STATE } from '../../utils/constants';
import StagedCardLayout from '../StagedCard/StagedCardLayout/StagedCardLayout';
import CardDetails from '../StagedCard/CardDetails/CardDetails';
import CardGoogleMap from '../StagedCard/CardGoogleMap/CardGoogleMap';
import CardGuaranteed from '../StagedCard/CardGuaranteed/CardGuaranteed';
import CardAction from '../StagedCard/CardAction/CardAction';
import partyIcon from '../../assets/img/icons/cards/party-icon.svg';
import reservationIcon from '../../assets/img/icons/cards/reservation-icon.svg';
import carIcon from '../../assets/img/icons/cards/car-icon.svg';
import flagIcon from '../../assets/img/icons/cards/flag-icon.svg';
import preShowIcon from '../../assets/img/icons/cards/pre-show-icon.svg';
import mapIcon from '../../assets/img/icons/cards/map-icon.svg';
import transportingIcon from "../../assets/img/icons/cards/transporting-icon.svg";
import helpIcon from '../../assets/img/icons/cards/help-icon.svg';
import { generateMailtoLink } from '../../utils/services';
import GetHelpModal from '../Modal/GetHelpModal';

import './limoGroupCardNew.scss';


type LimoGroupCardNewProps = {
  cart: any;
  datetime: any | null;
  time: string;
  isMissingInfo: boolean;
  isPublished: boolean;
  isServiceDeclined: boolean;
  serviceDeclined: string;
  packageDetailsId: number;
  setPackageOrderWithDetails: (data: any) => void;
  decryptedInvoiceId: any;
  setMissingInfoCards: any;
  cardDescription: string;
  cardGroup: string;
};

const LimoGroupCardNew = ({
  cart,
  datetime,
  time,
  isMissingInfo,
  isPublished,
  isServiceDeclined,
  serviceDeclined,
  packageDetailsId,
  setPackageOrderWithDetails,
  decryptedInvoiceId,
  setMissingInfoCards,
  cardDescription,
  cardGroup,
}: LimoGroupCardNewProps) => {
  const client = useApolloClient();
  
  const [emailLink, setEmailLink] = useState("");

  const [cardState, setCardState] = useState<{ packageDetailsId: number; cartData: any }>({
    packageDetailsId,
    cartData: {},
  });

  const [isFieldsValid, setIsFieldsValid] = useState({
    contactName: true,
    contactNumber: true,
    pickUpPlaceName: true,
    dropOffPlaceName: true,
  });
  
  const [isSubmitBtnDisabled, setIsSubmitBtnDisabled] = useState(true);
  
  const [isModalOpen, setIsModalOpen] = useState(false);
  
  const handleDeclineClick = async () => {
    if (window.confirm("Are you sure you want to decline this service?")) {
      const { data } = await client.mutate({
        mutation: DECLINE_SERVICE,
        variables: { packageDetailsId: packageDetailsId },
      });
      if (data?.declineService) {
        setPackageOrderWithDetails((prev: any) => {
          return {
            ...prev,
            orderDetails: prev.orderDetails.map((item: any) => {
              return item.packageDetailsId === packageDetailsId
                ? {
                    ...item,
                    cartDetails: {
                      ...item.cartDetails,
                      cartId: data?.declineService,
                      isServiceDeclined: SERVICE_STATE.DECLINED,
                    },
                  }
                : item;
            }),
          };
        });
        successMsg("Service Declined");
      }
    }
  };
  
  useEffect(() => {
    const requiredFields = ['contactName', 'contactNumber', 'pickUpPlaceName', 'dropOffPlaceName'];

    const isRequiredFieldsEmpty = requiredFields.some((el) => !cardState.cartData[el]);

    setIsSubmitBtnDisabled(isRequiredFieldsEmpty);
  }, [cardState.cartData]);
  
  useEffect(() => {
    generateMailtoLink(client, window.location.pathname, setEmailLink);
  }, [client]);
  
  const cartJsonData = JSON.parse(cart.cartDetails.cartJsonData);

  return (
    <StagedCardLayout
      cardIcon={transportingIcon}
      cardTitle={cart?.cartDetails?.cardTitle || "Limo Details"}
      datetime={datetime}
      time={time}
      isPublished={isPublished}
      isServiceDeclined={isServiceDeclined}
      serviceDeclined={serviceDeclined}
      isMissingInfo={isMissingInfo}
    >
      <>
        <GetHelpModal isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen} tag={cardGroup.replaceAll(' ', '')} />
        {isPublished &&
          <>
            <CardDetails
              cardIcon={carIcon}
              cardTitle="Vendor Name"
              cardSubtitle={cartJsonData?.vendorName}
            >
              <div className="transfer-card-content">
                <div className="card-content-column">
                  {cartJsonData?.vendorPhone && 
                    <p style={{ fontSize: '14px', fontWeight: '500', fontFamily: "'Inter', sans-serif" }}>Vendor phone</p>
                  }
                  {cartJsonData?.confirmationNumber && <p style={{ fontSize: '14px', fontWeight: '500', fontFamily: "'Inter', sans-serif" }}>Confirmation number</p>}
                </div>
                <div className="card-content-column">
                  {cartJsonData?.vendorPhone && 
                    <p style={{ fontSize: '14px', fontWeight: '400', fontFamily: "'Inter', sans-serif" }}>
                      <a href="tel:+">{cartJsonData?.vendorPhone}</a>
                    </p>
                  }
                  {cartJsonData?.confirmationNumber && <p style={{ fontSize: '14px', fontWeight: '400', fontFamily: "'Inter', sans-serif" }}>{cartJsonData?.confirmationNumber}</p>}
                </div>
              </div>
            </CardDetails>
            
            <div className="divider" />
          
            <CardDetails
              cardIcon={mapIcon}
              cardTitle="Pick Up At"
              cardSubtitleGoogleLink={{
                name: cartJsonData?.pickUpPlaceName, 
                address: cartJsonData?.pickUpAddress, 
                link: cartJsonData?.pickUpPlaceLink, 
                time: cartJsonData?.date || ''
              }}
            >
              <CardGoogleMap address={cartJsonData?.pickUpPlaceName || cartJsonData?.pickUpAddress} className="pl-4" />
            </CardDetails>
          
            <div className="divider" />
            <CardDetails
              cardIcon={preShowIcon}
              cardTitle="Pre Show Hospitality At"
              cardSubtitleGoogleLink={{
                name: cartJsonData?.restaurantName, 
                address: cartJsonData?.restaurantPlaceName, 
                link: cartJsonData?.restaurantPlaceLink, 
                time: cartJsonData?.preShowHospitality || ''
              }}
            >
              <CardGoogleMap address={cartJsonData?.restaurantPlaceName || cartJsonData?.restaurantName} className="pl-4" />
            </CardDetails>
            
            <div className="divider" />
            <CardDetails
              cardIcon={partyIcon}
              cardTitle="Event At"
              cardSubtitleGoogleLink={{
                name: cartJsonData?.venue, 
                address: cartJsonData?.venueAddress, 
                link: `https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(cartJsonData?.venueAddress || '')}`, 
                time: cartJsonData?.startTime || ''
              }}
            >
              <CardGoogleMap address={cartJsonData?.venueAddress || cartJsonData?.venue} className="pl-4" />
            </CardDetails>

            
            <div className="divider" />
            
            <CardDetails
              cardIcon={mapIcon}
              cardTitle="Drop Off At"
              cardSubtitleGoogleLink={{
                name: cartJsonData?.dropOffPlaceName, 
                address: cartJsonData?.dropOffAddress, 
                link: cartJsonData?.dropOffPlaceLink, 
                time: cartJsonData?.dropOff || ''
              }}
            >
              <CardGoogleMap address={cartJsonData?.dropOffPlaceName || cartJsonData?.dropOffAddress} className="pl-4" />
            </CardDetails>
            
            <div className="divider" />
          
            {cartJsonData?.notes &&
              <CardDetails
                cardIcon={flagIcon}
                cardTitle="Additional Notes"
                cardDescription={cartJsonData.notes}
              />
            }
          </>
        }

        {!isPublished && isMissingInfo && (
          <>
            <CardDetails
              isEditable={true}
              cardIcon={reservationIcon}
              cardTitle="Limo Details"
              cardSubtitle="Please provide your contact info"
            >
              <>
                <div className="divider"></div>
                <Form
                  onSubmit={async (e) => {
                    e.preventDefault();
                    const requiredFields = [
                      "contactName",
                      "contactNumber",
                      "pickUpPlaceName",
                      "dropOffPlaceName",
                    ];

                    const isRequiredFieldsEmpty = requiredFields.some((el) => {
                      return !cardState.cartData[el];
                    });

                    if (isRequiredFieldsEmpty) {
                      setIsFieldsValid({
                        contactName: !!cardState.cartData?.contactName,
                        contactNumber: !!cardState.cartData?.contactNumber,
                        pickUpPlaceName: !!cardState.cartData?.pickUpPlaceName,
                        dropOffPlaceName: !!cardState.cartData?.dropOffPlaceName,
                      });

                      errorMsg("Required Fields Should Not Be Empty");

                      return;
                    }

                    const { data } = await client.mutate({
                      mutation: SAVE_STAGE_ONE_CARD,
                      variables: { editCartDetailsInput: cardState },
                    });

                    const filtersData = await client.query({
                      query: GET_AVAILABLE_FILTERS_FOR_PACKAGE_INFO,
                      variables: { invoiceId: decryptedInvoiceId },
                      fetchPolicy: "no-cache",
                    });

                    if (data) {
                      setPackageOrderWithDetails((prev: any) => {
                        return {
                          ...prev,
                          orderDetails: [
                            ...prev.orderDetails.map((cart: any) => {
                              return cart.packageDetailsId === packageDetailsId
                                ? {
                                    ...cart,
                                    cartDetails: {
                                      ...cart.cartDetails,
                                      cardTitle: data?.saveStageOneCard.cardTitle,
                                      cartId: data?.saveStageOneCard.cartId,
                                      cartJsonData: data?.saveStageOneCard.cartJsonData,
                                      isPublished: data?.saveStageOneCard.isPublished,
                                      packageDetailsId: data?.saveStageOneCard.packageDetailsId,
                                    },
                                  }
                                : cart;
                            }),
                          ],
                        };
                      });

                      setMissingInfoCards(
                        filtersData.data?.getAvailableFiltersForPackageInfo.showMissingInfoFor,
                      );

                      successMsg("Saved");
                    }
                  }}
                >
                  <div className="inputs-container">
                    <div className="input-container">
                      <label className={!isFieldsValid.contactName ? "label-invalid" : ""}>
                        Contact Name
                      </label>
                      <div className="input-box">
                        <Input
                          className={!isFieldsValid.contactName ? "input-invalid" : ""}
                          type="text"
                          placeholder="Contact Name"
                          name="contactName"
                          invalid={!isFieldsValid.contactName}
                          onChange={(e) => {
                            setCardState((prev: any) => {
                              return {
                                ...prev,
                                cartData: { ...prev.cartData, [e.target.name]: e.target.value },
                              };
                            });
                          }}
                        />
                        <span></span>
                      </div>
                    </div>
                    <div className="input-container">
                      <label className={!isFieldsValid.contactNumber ? "label-invalid" : ""}>
                        Contact Number
                      </label>
                      <div className="input-box">
                        <Input
                          className={!isFieldsValid.contactNumber ? "input-invalid" : ""}
                          type="text"
                          placeholder="Contact Number"
                          name="contactNumber"
                          invalid={!isFieldsValid.contactNumber}
                          onChange={(e) => {
                            setCardState((prev: any) => {
                              return {
                                ...prev,
                                cartData: { ...prev.cartData, [e.target.name]: e.target.value },
                              };
                            });
                          }}
                        />
                        <span></span>
                      </div>
                    </div>{" "}
                    <div className="input-container">
                      <label className={!isFieldsValid.contactName ? "label-invalid" : ""}>
                        Pick-up Address
                      </label>
                      <div className="input-box">
                        <GooglePlaceAutocompleteInput
                          cardState={cardState}
                          setCardState={setCardState}
                          placeName={"pickUpPlaceName"}
                          placeValueName={"pickUpPlaceValue"}
                          placeLinkName={"pickUpPlaceLink"}
                          isInvalid={!isFieldsValid.pickUpPlaceName}
                        />
                        <span></span>
                      </div>
                    </div>
                    <div className="input-container">
                      <label className={!isFieldsValid.contactName ? "label-invalid" : ""}>
                        Drop-off Address
                      </label>
                      <div className="input-box">
                        <GooglePlaceAutocompleteInput
                          cardState={cardState}
                          setCardState={setCardState}
                          placeName={"dropOffPlaceName"}
                          placeValueName={"dropOffPlaceValue"}
                          placeLinkName={"dropOffPlaceLink"}
                          isInvalid={!isFieldsValid.dropOffPlaceName}
                        />
                        <span></span>
                      </div>
                    </div>
                    <div className="input-container">
                      <label>Celebration</label>
                      <Input
                        type="text"
                        placeholder="Are you celebrating a special occasion?"
                        name="celebration"
                        onChange={(e) => {
                          setCardState((prev: any) => {
                            return {
                              ...prev,
                              cartData: { ...prev.cartData, [e.target.name]: e.target.value },
                            };
                          });
                        }}
                      />
                    </div>
                  </div>
                  <div className="actions-container">
                    <CardDeclineBtn visible={isServiceDeclined} handleClick={handleDeclineClick} />
                    <Button disabled={isSubmitBtnDisabled} className="btn-round btn-icon d-flex align-items-center save-btn" type="submit">
                      Save
                    </Button>
                  </div>
                </Form>
              </>
            </CardDetails>
            <div className="divider"></div>
            <CardAction icon={helpIcon} text="Get help" 
              handleClick={() => {
                setIsModalOpen(true)
              }}
            />
          </>
        )}
        
        {!isPublished && !isMissingInfo &&
          <>
            <CardDetails
              isToggleExpanded={false}
              cardIcon={reservationIcon}
              cardTitle="Limo Details"
              cardSubtitle="Your services are guaranteed."
            >
              <div className="pl-4 mb-4">
                <CardGuaranteed text="Your services are guaranteed." />
                <div className="actions-container">
                  <CardDeclineBtn visible={isServiceDeclined} handleClick={handleDeclineClick} />
                </div>
                <div className="divider"></div> 
                <CardAction icon={helpIcon} text="Get help" 
                  handleClick={() => {
                  setIsModalOpen(true)
                }}
            />
              </div>
            </CardDetails>
          
            {cartJsonData?.notes &&
              <CardDetails
                cardIcon={flagIcon}
                cardTitle="Additional Notes"
                cardDescription={cartJsonData.notes}
              />
            }
          </>
        }
      </>
    </StagedCardLayout>
  );
};

export default LimoGroupCardNew;
