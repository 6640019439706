import { useEffect, useState } from 'react';
import { useApolloClient } from '@apollo/client';
import { Button, Form, Input } from 'reactstrap';
import moment from 'moment';
import { errorMsg, successMsg } from '../../cache/vars';
import { DECLINE_SERVICE, GET_AVAILABLE_FILTERS_FOR_PACKAGE_INFO, SAVE_STAGE_ONE_CARD } from '../../queries/vip-workflow';
import { SERVICE_STATE } from '../../utils/constants';
import StagedCardLayout from '../StagedCard/StagedCardLayout/StagedCardLayout';
import CardDetails from '../StagedCard/CardDetails/CardDetails';
import GooglePlaceAutocompleteInput from '../GooglePlaceAutocomplete/GooglePlaceAutocomplete';
import CardGoogleMap from '../StagedCard/CardGoogleMap/CardGoogleMap';
import CardGuaranteed from '../StagedCard/CardGuaranteed/CardGuaranteed';
import CardAction from '../StagedCard/CardAction/CardAction';
import CardDeclineBtn from '../StagedCard/CardDeclineBtn/CardDeclineBtn';
import FlightInfoPopover from '../Popovers/FlightInfoPopover';
import reservationIcon from '../../assets/img/icons/cards/reservation-icon.svg';
import flagIcon from '../../assets/img/icons/cards/flag-icon.svg';
import mapIcon from '../../assets/img/icons/cards/map-icon.svg';
import airportIcon from '../../assets/img/icons/cards/airport-icon.svg';
import jumpIcon from '../../assets/img/icons/cards/jump-icon.svg';
import planeIcon from '../../assets/img/icons/cards/plane-icon.svg';
import planeUpIcon from '../../assets/img/icons/cards/plane-up-icon.svg';
import helpIcon from '../../assets/img/icons/cards/help-icon.svg';
import carIcon from '../../assets/img/icons/cards/car-icon.svg';
import { generateMailtoLink } from '../../utils/services';
import GetHelpModal from '../Modal/GetHelpModal';

import './airportTransferDepartureGroupCardNew.scss';

type AirportTransferDepartureGroupCardNewProps = { 
  cart: any;
  datetime: any | null;
  time: string;
  isMissingInfo: boolean;
  isPublished: boolean;
  isServiceDeclined: boolean;
  serviceDeclined: string;
  packageDetailsId: number;
  decryptedInvoiceId: any;
  setMissingInfoCards: any;
  cardDescription: string;
  cardGroup: string;
  setPackageOrderWithDetails: (data: any) => void;
};

const AirportTransferDepartureGroupCardNew = ({
  cart,
  datetime,
  time,
  isMissingInfo,
  isPublished,
  isServiceDeclined,
  serviceDeclined,
  packageDetailsId,
  setPackageOrderWithDetails,
  decryptedInvoiceId,
  setMissingInfoCards,
  cardDescription,
  cardGroup,
}: AirportTransferDepartureGroupCardNewProps) => {
  const client = useApolloClient();
  
  const [emailLink, setEmailLink] = useState("");
  
  const [cardState, setCardState] = useState<{ packageDetailsId: number; cartData: any }>({
    packageDetailsId,
    cartData: {},
  });

  const [isFieldsValid, setIsFieldsValid] = useState({
    contactName: true,
    contactNumber: true,
    departureFlightDate: true,
    airportName: true,
    departureFlightAirline: true,
    departureFlightNumber: true,
  });
  
  const [isSubmitBtnDisabled, setIsSubmitBtnDisabled] = useState(true);
  
  const [isModalOpen, setIsModalOpen] = useState(false);
  
  const handleDeclineClick = async () => {
    if (window.confirm("Are you sure you want to decline this service?")) {
      const { data } = await client.mutate({
        mutation: DECLINE_SERVICE,
        variables: { packageDetailsId: packageDetailsId },
      });

      if (data?.declineService) {
        setPackageOrderWithDetails((prev: any) => {
          return {
            ...prev,
            orderDetails: prev.orderDetails.map((item: any) => {
              return item.packageDetailsId === packageDetailsId
                ? {
                    ...item,
                    cartDetails: {
                      ...item.cartDetails,
                      cartId: data?.declineService,
                      isServiceDeclined: SERVICE_STATE.DECLINED,
                    },
                  }
                : item;
            }),
          };
        });
        successMsg("Service Declined");
      }
    }
  };

  useEffect(() => {
    const requiredFields = [
      "contactName",
      "contactNumber",
      "departureFlightDate",
      "airportName",
      "departureFlightAirline",
      "departureFlightNumber",
    ];

    const isRequiredFieldsEmpty = requiredFields.some((el) => {
      return !cardState.cartData[el];
    });

    setIsSubmitBtnDisabled(isRequiredFieldsEmpty);
  }, [cardState.cartData]);
  
  useEffect(() => {
    generateMailtoLink(client, window.location.pathname, setEmailLink);
  }, [client]);
  
  const cartJsonData = JSON.parse(cart.cartDetails.cartJsonData);
  
  return (
    <StagedCardLayout
      cardIcon={airportIcon} 
      cardTitle={cart?.cartDetails?.cardTitle || "Airport Transfer Departure"}
      datetime={datetime}
      time={time}
      isPublished={isPublished} 
      isServiceDeclined={isServiceDeclined}
      serviceDeclined={serviceDeclined}
      isMissingInfo={isMissingInfo}
    >
      <>
        <GetHelpModal isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen} tag={cardGroup.replaceAll(' ', '')} />
        
        {isPublished &&
          <>
            <CardDetails
              cardIcon={carIcon}
              cardTitle="Transport Details"
              cardSubtitle={cartJsonData?.vendorName}
            >
              <>
                <div className="airport-transfer-card-content">
                  {cartJsonData?.vendorPhone &&
                    <>
                      <div className="card-content-column">
                        <p style={{ fontSize: '14px', fontWeight: '500', fontFamily: "'Inter', sans-serif" }}>Vendor Phone</p>
                      </div>
                      <div className="card-content-column">
                        <p style={{ fontSize: '14px', fontWeight: '400', fontFamily: "'Inter', sans-serif" }}>
                          <a href="tel:+">{cartJsonData?.vendorPhone}</a>
                        </p>
                      </div>
                    </>
                  }
                </div>
                <div className="airport-transfer-card-content">
                  {cartJsonData?.contactName &&
                    <>
                      <div className="card-content-column">
                        <p style={{ fontSize: '14px', fontWeight: '500', fontFamily: "'Inter', sans-serif" }}>Reserved for</p>
                      </div>
                      <div className="card-content-column">
                        <p style={{ fontSize: '14px', fontWeight: '400', fontFamily: "'Inter', sans-serif" }}>{cartJsonData?.contactName}</p>
                      </div>
                    </>
                  }
                </div>
                <div className="airport-transfer-card-content">
                  {cartJsonData?.contactNumber &&
                    <>
                      <div className="card-content-column">
                        <p style={{ fontSize: '14px', fontWeight: '500', fontFamily: "'Inter', sans-serif" }}>Contact Phone</p>
                      </div>
                      <div className="card-content-column">
                        <p style={{ fontSize: '14px', fontWeight: '400', fontFamily: "'Inter', sans-serif" }}>
                          <a href="tel:+">{cartJsonData?.contactNumber}</a>
                        </p>
                      </div>
                    </>
                  }
                </div>
              </>
            </CardDetails>
            
            <div className="divider" />
            
            <CardDetails
              cardIcon={planeUpIcon}
              cardTitle="Pick Up At"
              cardSubtitleGoogleLink={{
                name: cartJsonData?.hotelName, 
                address: cartJsonData?.hotelPlaceName, 
                link: cartJsonData?.hotelPlaceLink, 
                time: cartJsonData?.arrivalTime || ''
              }}
            >
              <div className="airport-transfer-card-content">
                {cartJsonData?.confirmation &&
                  <>
                    <div className="card-content-column">
                      <p style={{ fontSize: '14px', fontWeight: '500', fontFamily: "'Inter', sans-serif" }}>Confirmation</p>
                    </div>
                    <div className="card-content-column">
                      <p style={{ fontSize: '14px', fontWeight: '400', fontFamily: "'Inter', sans-serif" }}>{cartJsonData?.confirmation}</p>
                    </div>
                  </>
                }
              </div>

              <CardGoogleMap address={cartJsonData?.hotelPlaceName} className="pl-4" />
            </CardDetails>
            
            <div className="divider" />
            
            <CardDetails
              cardIcon={mapIcon}
              cardTitle="Transporting To"
              cardSubtitleGoogleLink={{
                name: cartJsonData?.airportName, 
                address: cartJsonData?.airportPlaceName, 
                link: cartJsonData?.airportPlaceLink, 
                time: cartJsonData?.date || ''
              }}
            >
              <div className="airport-transfer-card-content">
                {cartJsonData?.departureFlightNumber &&
                  <>
                    <div className="card-content-column">
                      <div>
                        <p style={{ fontSize: '14px', fontWeight: '400', fontFamily: "'Inter', sans-serif", display: 'inline'}}>Flight Num. {cartJsonData?.departureFlightNumber} <sup>*</sup> Departuring</p>
                        <span><FlightInfoPopover parsedCardDetails={cartJsonData} config={{name: 'flightInfo'}} /></span>
                      </div>
                    </div>
                    <div className="card-content-column">
                      <p>{moment(cartJsonData?.arrivalTime).format("h:mm a")}</p>
                    </div>
                  </>
                }
              </div>
              <CardGoogleMap address={cartJsonData?.airportPlaceName} className="pl-4" />
            </CardDetails>
            
            <div className="divider" />
            
            {cartJsonData?.notes &&
              <CardDetails
                cardIcon={flagIcon}
                cardTitle="Additional Notes"
                cardDescription={cartJsonData.notes}
              />
            }
          </>
        }

        {!isPublished && isMissingInfo && (
          <>
            <CardDetails
              isEditable={true}
              cardIcon={planeIcon}
              cardTitle="Arrival Details"
              cardSubtitle="Please provide your info"
            >
              <>
                <p className="mt-4 mr-4 mb-0 ml-2" style={{ fontSize: '14px', fontWeight: '400', fontFamily: "'Inter', sans-serif" }}>
                  Provide us with your arrival flight information. Transfers are reserved in a vehicle accommodating the size of your party associated to the package. Special vehicle types and additional stops will require an additional charge.
                </p>
                
                <div className="divider" />
                
                <div className="mb-4">
                  <p style={{ fontSize: '20px', fontWeight: '400', fontFamily: "'Inter', sans-serif" }}>Contact Details</p>
                  <p style={{ fontSize: '14px', fontWeight: '400', fontFamily: "'Inter', sans-serif" }} className="required-note">* These fields are required.</p>
                </div>
                
                <Form
                  onSubmit={async (e) => {
                    e.preventDefault();

                    const requiredFields = [
                      "contactName",
                      "contactNumber",
                      "departureFlightDate",
                      "airportName",
                      "departureFlightAirline",
                      "departureFlightNumber",
                    ];

                    const isRequiredFieldsEmpty = requiredFields.some((el) => {
                      return !cardState.cartData[el];
                    });

                    if (isRequiredFieldsEmpty) {
                      setIsFieldsValid({
                        contactName: !!cardState.cartData.contactName,
                        contactNumber: !!cardState.cartData.contactNumber,
                        departureFlightDate: !!cardState.cartData.departureFlightDate,
                        airportName: !!cardState.cartData.airportName,
                        departureFlightAirline: !!cardState.cartData.departureFlightAirline,
                        departureFlightNumber: !!cardState.cartData.departureFlightNumber,
                      });

                      errorMsg("Required Fields Should Not Be Empty");

                      return;
                    }

                    const { data } = await client.mutate({
                      mutation: SAVE_STAGE_ONE_CARD,
                      variables: { editCartDetailsInput: cardState },
                    });

                    const filtersData = await client.query({
                      query: GET_AVAILABLE_FILTERS_FOR_PACKAGE_INFO,
                      variables: { invoiceId: decryptedInvoiceId },
                      fetchPolicy: "no-cache",
                    });

                    if (data) {
                      setPackageOrderWithDetails((prev: any) => {
                        return {
                          ...prev,
                          orderDetails: [
                            ...prev.orderDetails.map((cart: any) => {
                              return cart.packageDetailsId === packageDetailsId
                                ? {
                                    ...cart,
                                    cartDetails: {
                                      ...cart.cartDetails,
                                      cardTitle: data?.saveStageOneCard.cardTitle,
                                      cartId: data?.saveStageOneCard.cartId,
                                      cartJsonData: data?.saveStageOneCard.cartJsonData,
                                      isPublished: data?.saveStageOneCard.isPublished,
                                      packageDetailsId: data?.saveStageOneCard.packageDetailsId,
                                    },
                                  }
                                : cart;
                            }),
                          ],
                        };
                      });

                      setMissingInfoCards(
                        filtersData.data?.getAvailableFiltersForPackageInfo.showMissingInfoFor,
                      );

                      successMsg("Saved");
                    }
                  }}>
                    <div className="inputs-container">
                    <div className="contacts-container">
                      <div className="input-container">
                        <label className={!isFieldsValid.contactName ? "label-invalid" : ""}>
                          Contact Name
                        </label>
                        <div className="input-box">
                          <Input
                            className={!isFieldsValid.contactName ? "input-invalid" : ""}
                            type="text"
                            placeholder="Contact Name"
                            name="contactName"
                            invalid={!isFieldsValid.contactName}
                            onChange={(e) => {
                              setCardState((prev: any) => {
                                return {
                                  ...prev,
                                  cartData: {
                                    ...prev.cartData,
                                    [e.target.name]: e.target.value,
                                  },
                                };
                              });
                            }}
                            value={cardState.cartData.contactName || ""}
                          />
                          <span></span>
                        </div>
                      </div>
                      <div className="input-container">
                        <label className={!isFieldsValid.contactNumber ? "label-invalid" : ""}>
                          Contact Number
                        </label>
                        <div className="input-box">
                          <Input
                            className={!isFieldsValid.contactNumber ? "input-invalid" : ""}
                            type="text"
                            placeholder="Contact Number"
                            name="contactNumber"
                            invalid={!isFieldsValid.contactNumber}
                            onChange={(e) => {
                              setCardState((prev: any) => {
                                return {
                                  ...prev,
                                  cartData: {
                                    ...prev.cartData,
                                    [e.target.name]: e.target.value,
                                  },
                                };
                              });
                            }}
                            value={cardState.cartData.contactNumber || ""}
                          />
                          <span></span>
                        </div>
                      </div>{" "}
                    </div>
                      
                      <div className="divider" />
                      
                      <div style={{ marginBottom: '20px' }}>
                        <p style={{ fontSize: '20px', fontWeight: '400', fontFamily: "'Inter', sans-serif" }}>Departure Flight Details</p>
                        <p className="required-note" style={{ fontSize: '14px', fontWeight: '400', fontFamily: "'Inter', sans-serif" }}>* These fields are required.</p>
                      </div>
                      
                      <div className="input-container">
                        <label>Pick-up Address (if other than package hotel)</label>
                        <div className="input-box">
                          <GooglePlaceAutocompleteInput
                            cardState={cardState}
                            setCardState={setCardState}
                            placeName={"pickUpPlaceName"}
                            placeValueName={"pickUpPlaceValue"}
                            placeLinkName={"pickUpPlaceLink"}
                          />
                          <span></span>
                        </div>
                      </div>
                      <div className="input-container">
                        <label
                          className={!isFieldsValid.departureFlightDate ? "label-invalid" : ""}
                        >
                          Departure Date
                        </label>
                        <div className="input-box">
                          <Input
                            style={{ color: '#71717A'}}
                            className={`custom-datetime-input ${!isFieldsValid.departureFlightDate ? "input-invalid" : ""}`}
                            type="datetime-local"
                            placeholder="Departure Flight Date"
                            name="departureFlightDate"
                            invalid={!isFieldsValid.departureFlightDate}
                            onChange={(e) => {
                              setCardState((prev: any) => {
                                return {
                                  ...prev,
                                  cartData: {
                                    ...prev.cartData,
                                    [e.target.name]: e.target.value,
                                  },
                                };
                              });
                            }}
                            value={cardState.cartData.departureFlightDate || ""}
                          />
                          <span></span>
                        </div>
                      </div>{" "}
                      <div className="input-container">
                        <label className={!isFieldsValid.airportName ? "label-invalid" : ""}>
                          Airport of Departure
                        </label>
                        <div className="input-box">
                          <Input
                            className={!isFieldsValid.airportName ? "input-invalid" : ""}
                            type="text"
                            placeholder="Departure Flight Airport"
                            name="airportName"
                            invalid={!isFieldsValid.airportName}
                            onChange={(e) => {
                              setCardState((prev: any) => {
                                return {
                                  ...prev,
                                  cartData: {
                                    ...prev.cartData,
                                    [e.target.name]: e.target.value,
                                  },
                                };
                              });
                            }}
                            value={cardState.cartData.airportName || ""}
                          />
                          <span></span>
                        </div>
                      </div>{" "}
                      <div className="input-container">
                        <label
                          className={!isFieldsValid.departureFlightAirline ? "label-invalid" : ""}
                        >
                          Airline
                        </label>
                        <div className="input-box">
                          <Input
                            className={
                              !isFieldsValid.departureFlightAirline ? "input-invalid" : ""
                            }
                            type="text"
                            placeholder="Departure Flight Airline"
                            name="departureFlightAirline"
                            invalid={!isFieldsValid.departureFlightAirline}
                            onChange={(e) => {
                              setCardState((prev: any) => {
                                return {
                                  ...prev,
                                  cartData: {
                                    ...prev.cartData,
                                    [e.target.name]: e.target.value,
                                  },
                                };
                              });
                            }}
                            value={cardState.cartData.departureFlightAirline || ""}
                          />
                          <span></span>
                        </div>
                      </div>{" "}
                      <div className="input-container">
                        <label
                          className={!isFieldsValid.departureFlightNumber ? "label-invalid" : ""}
                        >
                          Departure Flight Number
                        </label>
                        <div className="input-box">
                          <Input
                            className={
                              !isFieldsValid.departureFlightNumber ? "input-invalid" : ""
                            }
                            type="text"
                            placeholder="Departure Flight Number"
                            name="departureFlightNumber"
                            invalid={!isFieldsValid.departureFlightNumber}
                            onChange={(e) => {
                              setCardState((prev: any) => {
                                return {
                                  ...prev,
                                  cartData: {
                                    ...prev.cartData,
                                    [e.target.name]: e.target.value,
                                  },
                                };
                              });
                            }}
                            value={cardState.cartData.departureFlightNumber || ""}
                          />
                          <span></span>
                        </div>
                      </div>{" "}
                    </div>
                    
                  <div className="divider" />
                  
                  <div className="inputs-container">
                    <p style={{ fontSize: '20px', fontWeight: '400', fontFamily: "'Inter', sans-serif", margin: 0 }}>
                      Additional Requests
                    </p>
                    <div className="input-container">
                      <div className="input-box">
                        <Input
                          type="textarea"
                          placeholder="Additional Requests"
                          name="additionalRequests"
                          onChange={(e) =>
                            setCardState((prev) => ({
                              ...prev,
                              cartData: { ...prev.cartData, [e.target.name]: e.target.value },
                            }))
                          }
                        />
                        <span></span>
                      </div>
                    </div>
                    <div className="input-container">
                      <div className="input-box">
                        <Input
                          type="textarea"
                          placeholder="Are you celebrating a special occasion?"
                          name="celebration"
                          onChange={(e) =>
                            setCardState((prev) => ({
                              ...prev,
                              cartData: { ...prev.cartData, [e.target.name]: e.target.value },
                            }))
                          }
                        />
                        <span></span>
                      </div>
                    </div>
                  </div>
                  
                    
                  <div className="actions-container">
                    <CardDeclineBtn visible={isServiceDeclined} handleClick={handleDeclineClick} />
                    <Button disabled={isSubmitBtnDisabled} className="btn-round btn-icon d-flex align-items-center save-btn" type="submit">
                      Save
                    </Button>
                  </div>
                </Form>
              </>
            </CardDetails>

            <div className="divider"></div> 

            <CardAction icon={helpIcon} text="Get help" 
              handleClick={() => {
                setIsModalOpen(true)
              }}
            />
          </>
        )}

        {!isPublished && !isMissingInfo &&
          <>
            <CardDetails
              isToggleExpanded={false}
              cardIcon={reservationIcon}
              cardTitle="Departure Reservation Details"
              cardSubtitle="Your transportation is guaranteed."
            >
              <>
                <CardGuaranteed text="Your airport transfer is guaranteed." />
                <div className="actions-container">
                  <CardDeclineBtn visible={isServiceDeclined} handleClick={handleDeclineClick} />
                </div>
              </>
            </CardDetails>
            
            {/* <div className="divider"></div>
            
            <CardAction icon={jumpIcon} text="Jump to departure details" handleClick={() => alert('Jump to departure details')} /> */}
            
            <div className="divider"></div> 

            <CardAction icon={helpIcon} text="Get help" 
              handleClick={() => {
                setIsModalOpen(true)
              }}
            />
          </>
        }
      </>
    </StagedCardLayout>
  );
};

export default AirportTransferDepartureGroupCardNew;