import { useEffect, useState } from 'react';
import { useApolloClient } from '@apollo/client';
import { Button, Form, Input } from 'reactstrap';
import moment from 'moment';
import { errorMsg, successMsg } from '../../cache/vars';
import { DECLINE_SERVICE, GET_AVAILABLE_FILTERS_FOR_PACKAGE_INFO, SAVE_STAGE_ONE_CARD } from '../../queries/vip-workflow';
import { SERVICE_STATE } from '../../utils/constants';
import StagedCardLayout from '../StagedCard/StagedCardLayout/StagedCardLayout';
import CardDetails from '../StagedCard/CardDetails/CardDetails';
import CardGoogleMap from '../StagedCard/CardGoogleMap/CardGoogleMap';
import CardGuaranteed from '../StagedCard/CardGuaranteed/CardGuaranteed';
import CardAction from '../StagedCard/CardAction/CardAction';
import CardDeclineBtn from '../StagedCard/CardDeclineBtn/CardDeclineBtn';
import { generateMailtoLink } from '../../utils/services';
import bellIcon from '../../assets/img/icons/cards/bell-icon.svg';
import reservationIcon from '../../assets/img/icons/cards/reservation-icon.svg';
import bedIcon1 from '../../assets/img/icons/cards/bed-icon-1.svg';
import bedIcon2 from '../../assets/img/icons/cards/bed-icon-2.svg';
import flagIcon from '../../assets/img/icons/cards/flag-icon.svg';
import mapIcon from '../../assets/img/icons/cards/map-icon.svg';
import hotelIcon from "../../assets/img/icons/cards/hotel-icon.svg";
import helpIcon from '../../assets/img/icons/cards/help-icon.svg';
import GetHelpModal from '../Modal/GetHelpModal';

import './hotelGroupCardNew.scss';

type HotelGroupCardNewProps = {
  cart: any;
  datetime: any | null;
  time: any;
  isMissingInfo: boolean;
  isPublished: boolean;
  isServiceDeclined: boolean;
  serviceDeclined: string;
  packageDetailsId: number;
  setPackageOrderWithDetails: (data: any) => void;
  decryptedInvoiceId: any;
  setMissingInfoCards: any;
  cardDescription: string;
  cardGroup: string;
};

const HotelGroupCardNew = ({
  cart,
  datetime,
  time,
  isMissingInfo,
  isPublished,
  isServiceDeclined,
  serviceDeclined,
  packageDetailsId,
  setPackageOrderWithDetails,
  decryptedInvoiceId,
  setMissingInfoCards,
  cardDescription,
  cardGroup,
}: HotelGroupCardNewProps) => {
  const client = useApolloClient();
  
  const [emailLink, setEmailLink] = useState("");

  const [cardState, setCardState] = useState<{ packageDetailsId: number; cartData: any }>({
    packageDetailsId,
    cartData: {},
  });

  const [isFieldsValid, setIsFieldsValid] = useState({
    checkInFirstName: true,
    checkInLastName: true,
    contactNumber: true,
    bedsNumber: true,
  });

  const [isSubmitBtnDisabled, setIsSubmitBtnDisabled] = useState(true);
  
  const [isModalOpen, setIsModalOpen] = useState(false);
  
  const handleDeclineClick = async () => {
    if (window.confirm('Are you sure you want to decline this service?')) {
      const { data } = await client.mutate({
        mutation: DECLINE_SERVICE,
        variables: { packageDetailsId: packageDetailsId },
      });
      if (data?.declineService) {
        setPackageOrderWithDetails((prev: { orderDetails: { packageDetailsId: number; cartDetails: any; }[]; }) => ({
          ...prev,
          orderDetails: prev.orderDetails.map((item: { packageDetailsId: number; cartDetails: any; }) =>
            item.packageDetailsId === packageDetailsId
              ? {
                ...item,
                cartDetails: {
                  ...item.cartDetails,
                  cartId: data?.declineService,
                  isServiceDeclined: SERVICE_STATE.DECLINED,
                },
              }
              : item
          ),
        }));
        successMsg('Service Declined');
      }
    }
  }

  useEffect(() => {
    const requiredFields = ['checkInFirstName', 'checkInLastName', 'contactNumber', 'bedsNumber'];

    const isRequiredFieldsEmpty = requiredFields.some((el) => !cardState.cartData[el]);

    setIsSubmitBtnDisabled(isRequiredFieldsEmpty);
  }, [cardState.cartData]);
  
  useEffect(() => {
    generateMailtoLink(client, window.location.pathname, setEmailLink);
  }, [client]);

  const cartJsonData = JSON.parse(cart.cartDetails.cartJsonData);

  return (
    <StagedCardLayout
      cardIcon={hotelIcon}
      cardTitle={cart?.cartDetails?.cardTitle || "Hotel Details"}
      datetime={datetime}
      time={time}
      isPublished={isPublished}
      isServiceDeclined={isServiceDeclined}
      serviceDeclined={serviceDeclined}
      isMissingInfo={isMissingInfo}
    >
      <>
        <GetHelpModal isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen} tag={cardGroup.replaceAll(' ', '')} />
        {isPublished &&
          <>
          {cartJsonData?.date &&
            <>
              <CardDetails
                isToggleExpanded={false}
              >
                <div className="hotel-check-inout">
                  <img loading="lazy" src={bellIcon} alt="bell-icon" className="hotel-check-icon" />
                  <div className="hotel-check-details">
                    <div className="hotel-check-title">Check-in</div>
                    <div className="hotel-check-date">
                      {cartJsonData?.date ? moment(cartJsonData.date).format("ddd, MMM Do") : '-'}
                    </div>
                    <div className="hotel-check-time">
                      {cartJsonData?.date ? moment(cartJsonData.date).format("h:mm A") : '-'}
                    </div>
                  </div>
                  <div className="hotel-check-details">
                    <div className="hotel-check-title">Check-out</div>
                    <div className="hotel-check-date">
                      {cartJsonData?.date ? moment(cartJsonData.date).add(cartJsonData?.numberOfNights || 0, 'days').format("ddd, MMM Do") : '-'}
                    </div>
                  </div>
                </div>
              </CardDetails>
              
              <div className="divider"></div>
            </>
          }         
            
            {cartJsonData?.placeName &&
              <>
                <CardDetails
                  cardIcon={mapIcon}
                  cardTitle={cartJsonData?.hotelName || ''}
                  cardSubtitleGoogleLink={{
                    name: cartJsonData?.hotelName, 
                    address: cartJsonData?.placeName, 
                    link: cartJsonData?.placeLink, 
                    time: cartJsonData?.date || ''
                  }}
                >
                  <div className="hotel-card-content">
                    <p className="pr-2" style={{ fontSize: '16px', fontWeight: '500', fontFamily: "'Inter', sans-serif", color: "#101010" }}>Phone:</p>
                    <a href={`tel:${cartJsonData?.hotelPhone}`} className="phone-link">
                      {cartJsonData?.hotelPhone}
                    </a>
                  </div>
                  <CardGoogleMap address={cartJsonData?.placeName} className="pl-4" />
                </CardDetails>
              
                <div className="divider" />
              </>
            }
            
            <CardDetails
              cardIcon={reservationIcon}
              cardTitle="Reservation Details"
              cardSubtitle={`Nights ${cartJsonData.numberOfNights || 'TBD'}, ${cartJsonData.roomsNumber || 'TBD'} Room`}
            >
              {/* <div className="hotel-card-content">
                <div className="card-content-column">
                  {cartJsonData?.date && <p>Check-in</p>}
                  {cartJsonData?.reservationName && <p>Reservation First Name</p>}
                  {cartJsonData?.reservationName && <p>Reservation Last Name</p>}
                  {cartJsonData?.occupantsNumber && <p>Occupants</p>}
                  {cartJsonData?.confirmation && <p>Confirmation</p>}
                </div>
                <div className="card-content-column">
                  cartJsonData?.date && <p>{cartJsonData?.date ? moment(cartJsonData.date).format("h:mm a") : '-'}</p>}
                  {cartJsonData?.reservationName && <p>{cartJsonData?.reservationName.split(' ')[0]}</p>}
                  {cartJsonData?.reservationName && <p>{cartJsonData?.reservationName.split(' ')[1]}</p>}
                  {cartJsonData?.occupantsNumber && <p>{cartJsonData?.occupantsNumber}</p>}
                  {cartJsonData?.confirmation && <p>{cartJsonData?.confirmation}</p>}
                </div>
              </div> */}
              <div className="hotel-card-content">
                <div className="card-content-column">
                  {cartJsonData?.date && <p style={{ fontSize: '14px', fontWeight: '500', fontFamily: "'Inter', sans-serif" }}>Check-in</p>}
                </div>
                <div className="card-content-column">
                  {cartJsonData?.date && <p style={{ fontSize: '14px', fontWeight: '400', fontFamily: "'Inter', sans-serif" }}>{cartJsonData?.date ? moment(cartJsonData.date).format("h:mm a") : '-'}</p>}
                </div>
              </div>
              <div className="hotel-card-content">
                <div className="card-content-column">
                  {cartJsonData?.reservationName && <p style={{ fontSize: '14px', fontWeight: '500', fontFamily: "'Inter', sans-serif" }}>Reservation First Name</p>}
                </div>
                <div className="card-content-column">
                  {cartJsonData?.reservationName && <p style={{ fontSize: '14px', fontWeight: '400', fontFamily: "'Inter', sans-serif" }}>{cartJsonData?.checkInFirstName}</p>}
                </div>
              </div>
              <div className="hotel-card-content">
                <div className="card-content-column">
                  {cartJsonData?.reservationName && <p style={{ fontSize: '14px', fontWeight: '500', fontFamily: "'Inter', sans-serif" }}>Reservation Last Name</p>}
                </div>
                <div className="card-content-column">
                  {cartJsonData?.reservationName && <p style={{ fontSize: '14px', fontWeight: '400', fontFamily: "'Inter', sans-serif" }}>{cartJsonData?.checkInLastName}</p>}
                </div>
              </div>
              <div className="hotel-card-content">
                <div className="card-content-column">
                  {cartJsonData?.occupantsNumber && <p style={{ fontSize: '14px', fontWeight: '500', fontFamily: "'Inter', sans-serif" }}>Occupants</p>}
                </div>
                <div className="card-content-column">
                  {cartJsonData?.occupantsNumber && <p style={{ fontSize: '14px', fontWeight: '400', fontFamily: "'Inter', sans-serif" }}>{cartJsonData?.occupantsNumber}</p>}
                </div>
              </div>
              <div className="hotel-card-content">
                <div className="card-content-column">
                  {cartJsonData?.confirmation && <p style={{ fontSize: '14px', fontWeight: '500', fontFamily: "'Inter', sans-serif" }}>Confirmation</p>}
                </div>
                <div className="card-content-column">
                  {cartJsonData?.confirmation && <p style={{ fontSize: '14px', fontWeight: '400', fontFamily: "'Inter', sans-serif" }}>{cartJsonData?.confirmation}</p>}
                </div>
              </div>
            </CardDetails>
            
            <div className="divider" />
            
            {cartJsonData?.notes &&
              <>
                <CardDetails
                cardIcon={flagIcon}
                cardTitle="Additional Notes"
                cardDescription={cartJsonData?.notes}
                />
              </>
            }
          </>
        }

        {!isPublished && isMissingInfo && (
          <>
            <CardDetails
              isEditable={true}
              cardIcon={reservationIcon}
              cardTitle="Reservation Details"
              cardSubtitle="Please provide your contact info"
            >
              <>
                <div className="divider"></div>
                <Form
                  onSubmit={async (e) => {
                    e.preventDefault();
                    const requiredFields = ['checkInFirstName', 'checkInLastName', 'contactNumber', 'bedsNumber'];

                    const isRequiredFieldsEmpty = requiredFields.some((el) => !cardState.cartData[el]);

                    if (isRequiredFieldsEmpty) {
                      setIsFieldsValid({
                        checkInFirstName: !!cardState.cartData?.checkInFirstName,
                        checkInLastName: !!cardState.cartData?.checkInLastName,
                        contactNumber: !!cardState.cartData?.contactNumber,
                        bedsNumber: !!cardState.cartData?.bedsNumber,
                      });

                      errorMsg('Required Fields Should Not Be Empty');
                      return;
                    }

                    const { data } = await client.mutate({
                      mutation: SAVE_STAGE_ONE_CARD,
                      variables: { editCartDetailsInput: cardState },
                    });

                    const filtersData = await client.query({
                      query: GET_AVAILABLE_FILTERS_FOR_PACKAGE_INFO,
                      variables: { invoiceId: decryptedInvoiceId },
                      fetchPolicy: 'no-cache',
                    });

                    if (data) {
                      setPackageOrderWithDetails((prev: any) => ({
                        ...prev,
                        orderDetails: prev.orderDetails.map((cart: any) =>
                          cart.packageDetailsId === packageDetailsId
                            ? {
                              ...cart,
                              cartDetails: {
                                ...cart.cartDetails,
                                cardTitle: data?.saveStageOneCard.cardTitle,
                                cartId: data?.saveStageOneCard.cartId,
                                cartJsonData: data?.saveStageOneCard.cartJsonData,
                                isPublished: data?.saveStageOneCard.isPublished,
                                packageDetailsId: data?.saveStageOneCard.packageDetailsId,
                              },
                            }
                            : cart
                        ),
                      }));

                      setMissingInfoCards(filtersData.data?.getAvailableFiltersForPackageInfo.showMissingInfoFor);
                      successMsg('Saved');
                    }
                  }}
                >
                  <div className="inputs-container">
                    <div>
                      <p className="section-title" style={{ fontSize: '20px', fontWeight: '400', fontFamily: "'Inter', sans-serif" }}>Contact Details</p>
                      <p className="required-note" style={{ fontSize: '14px', fontWeight: '400', fontFamily: "'Inter', sans-serif" }}>* These fields are required.</p>
                    </div>
                    <div className="input-container-names">
                      <div className="input-container mr-2">
                        <label className={!isFieldsValid.checkInFirstName ? 'label-invalid' : ''}>Check-in First Name</label>
                        <div className="input-box">
                          <Input
                            className={!isFieldsValid.checkInFirstName ? 'input-invalid' : ''}
                            type="text"
                            placeholder="Check-in First Name*"
                            name="checkInFirstName"
                            invalid={!isFieldsValid.checkInFirstName}
                            onChange={(e) =>
                              setCardState((prev) => ({
                                ...prev,
                                cartData: { ...prev.cartData, [e.target.name]: e.target.value },
                              }))
                            }
                          />
                          <span></span>
                        </div>
                      </div>
                      <div className="input-container">
                        <label className={!isFieldsValid.checkInLastName ? 'label-invalid' : ''}>Check-in Last Name</label>
                        <div className="input-box">
                          <Input
                            className={!isFieldsValid.checkInLastName ? 'input-invalid' : ''}
                            type="text"
                            placeholder="Check-in Last Name*"
                            name="checkInLastName"
                            invalid={!isFieldsValid.checkInLastName}
                            onChange={(e) =>
                              setCardState((prev) => ({
                                ...prev,
                                cartData: { ...prev.cartData, [e.target.name]: e.target.value },
                              }))
                            }
                          />
                          <span></span>
                        </div>
                      </div>
                    </div>
                    <div className="input-container">
                      <label className={!isFieldsValid.contactNumber ? 'label-invalid' : ''}>Contact Number</label>
                      <div className="input-box">
                        <Input
                          className={!isFieldsValid.contactNumber ? 'input-invalid' : ''}
                          type="text"
                          placeholder="Contact Number*"
                          name="contactNumber"
                          invalid={!isFieldsValid.contactNumber}
                          onChange={(e) =>
                            setCardState((prev) => ({
                              ...prev,
                              cartData: { ...prev.cartData, [e.target.name]: e.target.value },
                            }))
                          }
                        />
                        <span></span>
                      </div>
                    </div>
                  </div>

                  <div className="divider"></div>

                  <div className="inputs-container bed-request">
                    <div>
                      <label className="bed-request-label">Bed Request</label>
                      <p className="required-note" style={{ fontSize: '14px', fontWeight: '400', fontFamily: "'Inter', sans-serif" }}>* These fields are required.</p>
                      <p className="bed-request-note">Bed requests are not guaranteed but will be requested on your behalf.</p>
                    </div>
                    <div className="bed-options">
                      <button
                        type="button"
                        className={`bed-option ${cardState.cartData.bedsNumber === '1' ? 'active' : ''}`}
                        onClick={() =>
                          setCardState((prev) => ({
                            ...prev,
                            cartData: { ...prev.cartData, bedsNumber: '1' },
                          }))
                        }
                      >
                        <img src={bedIcon1} alt="1 Bed Icon" />
                        1 Bed
                      </button>
                      <button
                        type="button"
                        className={`bed-option ${cardState.cartData.bedsNumber === '2' ? 'active' : ''}`}
                        onClick={() =>
                          setCardState((prev) => ({
                            ...prev,
                            cartData: { ...prev.cartData, bedsNumber: '2' },
                          }))
                        }
                      >
                        <img src={bedIcon2} alt="2 Beds Icon" />
                        2 Beds
                      </button>
                    </div>
                    {!isFieldsValid.bedsNumber && <p className="required-note">Choose one bed option.</p>}
                  </div>
                  
                  <div className="divider"></div>
                  <div className="inputs-container">
                    <p className="section-title">
                      Additional Requests
                    </p>
                    <div className="input-containers">
                      <div className="input-container">
                        <div className="input-box">
                          <Input
                            type="textarea"
                            placeholder="Add special requests here..."
                            name="additionalRequests"
                            onChange={(e) =>
                              setCardState((prev) => ({
                                ...prev,
                                cartData: { ...prev.cartData, [e.target.name]: e.target.value },
                              }))
                            }
                          />
                          <span></span>
                        </div>
                      </div>
                      <div className="input-container">
                        <div className="input-box">
                          <Input
                            type="textarea"
                            placeholder="Are you celebrating a special occasion?"
                            name="celebration"
                            onChange={(e) =>
                              setCardState((prev) => ({
                                ...prev,
                                cartData: { ...prev.cartData, [e.target.name]: e.target.value },
                              }))
                            }
                          />
                          <span></span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="actions-container">
                    <CardDeclineBtn visible={isServiceDeclined} handleClick={handleDeclineClick} />
                    <Button disabled={isSubmitBtnDisabled} className="btn-round btn-icon d-flex align-items-center save-btn" type="submit">
                      Save
                    </Button>
                  </div>
                </Form>
              </>
            </CardDetails>
            <div className="divider"></div>
            <CardAction icon={helpIcon} text="Get help" 
              handleClick={() => {
                setIsModalOpen(true)
              }}
            />
          </>
        )}
        
        {!isPublished && !isMissingInfo &&
          <>
            <CardDetails
              isToggleExpanded={false}
              cardIcon={reservationIcon}
              cardTitle="Hotel Accomodations"
              cardSubtitle="Your accommodations are guaranteed."
            >
              <div className="pl-4 mb-4">
                <CardGuaranteed text="Your accommodation are guaranteed." />
                <div className="actions-container">
                  <CardDeclineBtn visible={isServiceDeclined} handleClick={handleDeclineClick} />
                </div>
                <div className="divider"></div> 
                <CardAction icon={helpIcon} text="Get help" 
                  handleClick={() => {
                    setIsModalOpen(true)
                  }}
                />
              </div>
            </CardDetails>
          
            {cartJsonData?.notes &&
              <CardDetails
                cardIcon={flagIcon}
                cardTitle="Additional Notes"
                cardDescription={cartJsonData.notes}
              />
            }
          </>
        }
      </>
    </StagedCardLayout>
  );
};

export default HotelGroupCardNew;
